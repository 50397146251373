import type { ComponentType, FC } from 'react';
import React, { useContext } from 'react';

import type { ExternalShareContextType } from './ExternalShareContext';
import { ExternalShareContext } from './ExternalShareContext';

export function withExternalShareContext<TOriginalProps>(
	WrappedComponent: ComponentType<TOriginalProps & ExternalShareContextType>,
): FC<Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof ExternalShareContextType>>> {
	function WithExternalShareContext(
		props: Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof ExternalShareContextType>>,
	) {
		const externalShareContext = useContext(ExternalShareContext);
		return <WrappedComponent {...externalShareContext} {...(props as TOriginalProps)} />;
	}

	WithExternalShareContext.displayName = `withExternalShareContext(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;

	return WithExternalShareContext;
}
