import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { getSessionData } from '@confluence/session-data';
import { getUserPermissionFromQuery } from '@confluence/external-collab-ui';
import {
	GlobalOperationsUserPermissionTypeQuery,
	SitePermissionType,
} from '@confluence/external-collab-ui/entry-points/GlobalOperationsUserPermissionTypeQuery';
import type { GlobalOperationsUserPermissionTypeQueryType as GlobalOperationsUserPermissionType } from '@confluence/external-collab-ui/entry-points/GlobalOperationsUserPermissionTypeQuery';

export const getCanViewUserProfile = async (): Promise<boolean> => {
	await getSessionData();

	// External Collaborators should not have access to view user profile
	const { data: userPermissionData }: any = await getApolloClient()
		.query<GlobalOperationsUserPermissionType>({
			query: GlobalOperationsUserPermissionTypeQuery,
			errorPolicy: 'all',
		})
		.catch((error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.ADMIN_EXPERIENCE,
			});
			markErrorAsHandled(error);
			return new Error('PERMISSION ERROR');
		});

	const isExternalCollaborator =
		getUserPermissionFromQuery(userPermissionData) === SitePermissionType.EXTERNAL;

	return !isExternalCollaborator;
};
