import gql from 'graphql-tag';

import { UserPermissionFragment } from './Fragments/UserPermissionFragment.fragment';

export const GlobalOperationsUserPermissionTypeQuery =
	// eslint-disable-next-line graphql-relay-compat/no-apollo-fragments -- Read https://go/connie-relay-migration-fyi
	gql`
		query GlobalOperationsUserPermissionTypeQuery {
			...UserPermissionFragment
		}

		${UserPermissionFragment}
	`;
