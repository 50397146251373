/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GlobalOperationsUserPermissionTypeQuery
// ====================================================

export interface GlobalOperationsUserPermissionTypeQuery_user_confluence {
  permissionType: SitePermissionType | null;
}

export interface GlobalOperationsUserPermissionTypeQuery_user {
  id: string | null;
  confluence: GlobalOperationsUserPermissionTypeQuery_user_confluence | null;
}

export interface GlobalOperationsUserPermissionTypeQuery {
  user: GlobalOperationsUserPermissionTypeQuery_user | null;
}
