import type { ComponentType, FC } from 'react';
import React, { useContext } from 'react';

import type { CoreInvitesContextType } from './CoreInvitesContext';
import { CoreInvitesContext } from './CoreInvitesContext';

export function withCoreInvitesContext<TOriginalProps>(
	WrappedComponent: ComponentType<TOriginalProps & CoreInvitesContextType>,
): FC<Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof CoreInvitesContextType>>> {
	function WithCoreInvitesContext(
		props: Pick<TOriginalProps, Exclude<keyof TOriginalProps, keyof CoreInvitesContextType>>,
	) {
		const coreInvitesContext = useContext(CoreInvitesContext);
		return <WrappedComponent {...coreInvitesContext} {...(props as TOriginalProps)} />;
	}

	WithCoreInvitesContext.displayName = `withCoreInvitesContext(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;

	return WithCoreInvitesContext;
}
